import MDRouter from "@/service/router";
import domain from '@/service/domain';
import { env } from "md-base-tools/env";
import { getExtractCardList, GetLuckycardsHomeBanner } from "@/api/index";
import { GoBack } from "@/utils/tools";
import CompEWM from '@@/luckycards/popupEWM.vue';
import { getSchemeUrl } from "@/utils/toolsApi";
import ClipboardJS from "clipboard";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    CompEWM
  },
  computed: {
    shareInfo() {
      if (this.list && this.list.length) {
        return this.list[0];
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      env,
      loading: false,
      finished: false,
      list: [],
      page: 1,
      page_size: 10,
      total: 0,
      showEWM: false,
      swipeList: [],
      swipeIndex: 0
    };
  },
  mounted() {
    this.getAdList();
  },
  beforeMount() {
    // 打点
    this.sensors.track("LuckycardsList", {});
  },
  methods: {
    async getList() {
      this.loading = true;
      const {
        status,
        data
      } = await getExtractCardList({
        page: this.page,
        page_size: this.page_size
      });
      if (status == 0 && data.list && data.list.length) {
        this.list.push(...data.list);
        this.page += 1;
        this.total = data.total;
      }
      if (this.list.length >= this.total || !data.list || data.list && data.list.length == 0) {
        this.finished = true;
      } else {
        this.finished = false;
      }
      this.loading = false;
    },
    goBack() {
      GoBack();
    },
    goInfo(item) {
      if (item.is_collect) {
        return this.goCollect(item);
      }
      MDRouter.goLuckycardsInfo(item.id);
    },
    // 去开团详情
    goGroupInfo(e) {
      let url = '';
      const VUE_env = process.env.VUE_APP_ENV;
      if (VUE_env && VUE_env != 'pre') {
        url = `md://mini_program?id=wx3b0aeea5ba10856c&name=gh_5638b10d3ccb&path=pages/luckycards/group/group?id=${e.id}&group_id=${e.group_info.id}`;
      } else {
        url = `md://mini_program?id=wx6a3c68659310e879&name=gh_7210ac3f1f08&path=pages/luckycards/group/group?id=${e.id}&group_id=${e.group_info.id}`;
      }
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "openController",
        functionParams: url,
        webFun: async () => {
          const param = {
            path: "/pages/index/index",
            query: `nextUrl=pages/luckycards/group/group&id=${e.id}&group_id=${e.group_info.id}`
          };
          const option = {
            jump_wxa: JSON.stringify(param)
          };
          const url = await getSchemeUrl(option);
          if (url) {
            if (env.isWeibo()) {
              const clipboard = new ClipboardJS(e.target, {
                text: () => url
              });
              clipboard.on('success', () => {
                mdToast('内容已复制，请前往手机浏览器查看');
                // 释放内存
                clipboard.off('error');
                clipboard.off('success');
                clipboard.destroy();
              });
              clipboard.on('error', () => {
                // 不支持复制
                mdToast({
                  type: 'waning',
                  message: '该浏览器不支持自动复制'
                });
                // 释放内存
                clipboard.off('error');
                clipboard.off('success');
                clipboard.destroy();
              });
              clipboard.onClick(e);
            } else {
              location.href = url;
            }
          }
        }
      });
    },
    // 展示二维码弹层
    showJoin() {
      this.showEWM = true;
    },
    // 分享
    setShare() {
      if (!this.shareInfo) return;
      let url = `${domain.wap}/luckycards/fastsell/list?utm_source=Othershare&utm_content=${this.userId}`;
      let share_option = {
        url,
        img: this.shareInfo.list_main_image_url,
        msg_des: `向你分享好玩的在线抽卡项目 ｜ 摩点 ${url}`,
        qq_des: '向你分享好玩的在线抽卡项目 ｜ 摩点',
        qq_share_url: `${url}`,
        qq_title: '向你分享好玩的在线抽卡项目 ｜ 摩点',
        qr_img: "",
        qzone_share_url: `${url}`,
        share_channel: "",
        share_url: `${url}`,
        sharelink_url: `${url}`,
        weibo_des: `向你分享好玩的在线抽卡项目 ｜ 摩点 ${url}（分享自@摩点）`,
        weibo_share_url: `${url}`,
        weibo_title: '向你分享好玩的在线抽卡项目 ｜ 摩点',
        weixin_share_url: `${url}`,
        wxTimeline_share_url: `${url}`,
        wx_des: `向你分享好玩的在线抽卡项目 ｜ 摩点${url}`,
        wx_title: '向你分享好玩的在线抽卡项目 ｜ 摩点',
        mini_programs_title: `向你分享好玩的在线抽卡项目 ｜ 摩点${url}`,
        mina_share_img: this.shareInfo.list_main_image_url,
        mina_app_name: 'gh_7210ac3f1f08',
        mina_share_path: '/pages/index/index?nextUrl=pages/luckycards/list/list'
      };
      // eslint-disable-next-line no-undef
      userHeader.setShareInfo(share_option);
    },
    // 分享
    onShare() {
      if (!env.isWxApp()) {
        this.setShare();
        // eslint-disable-next-line no-undef
        userHeader.shareShow();
      }
    },
    swipeChange(index) {
      this.swipeIndex = index;
    },
    // 去合集列表
    goCollect(item) {
      MDRouter.goLuckycardsCollectList(item.collect_id);
    },
    // 获取广告
    getAdList() {
      GetLuckycardsHomeBanner().then(res => {
        if (res.status == 0 && res.data) {
          this.swipeList = res.data.ads;
        }
      });
    }
  }
};